import { APP_INITIALIZER, Injector, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommentTypesEffects } from './store/comment-types/comment-types.effects';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './layout/main/main.component';
import { PageLoaderComponent } from './shared/component/page-loader/page-loader.component';
import { TopLoaderComponent } from './shared/component/top-loader/top-loader.component';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HelperService } from './shared/service/helper.service';
import { MenuItems } from './shared/helper/menu-items/menu-items';
import { ToastrModule } from 'ngx-toastr';
import { WINDOW_PROVIDERS } from './window.providers';

import { Store, StoreModule } from '@ngrx/store';
import { AppHttpInterceptor } from './http.interceptor';
import { MainModule } from './layout/main/main.module';
import { ProductionReviewEffects } from './store/production-review/production-review.effects';
import { EffectsModule } from '@ngrx/effects';
import { oeeAppReducer, OeeAppState } from './store/oee.reducer';
import { SiteLineSelectionEffects } from './store/site-line-selection/site-line-selection.effects';
import { UserEffects } from './store/user/user.effects';
import { HomeEffects } from './store/home/home.effects';
import { AppEffects } from './store/app/effects';
import { HomeActivityTreeChartService } from './store/home/home-activity-tree-chart.service';
import { ActivityHistoryEffects } from './store/activity-history/activity-history.effects';
import { MainEffects } from './store/main/main.effects';
import { ActivityReviewEffects } from './store/activity-review/activity-review.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CheckForUpdateService } from './check-for-update.service';
import { SchedulerEffects } from './store/scheduler/scheduler.effects';
import { SchedulerScenarioEffects } from './store/scheduler-scenario/scheduler-scenario.effects';
import { ActivityButtonEffects } from './store/activity-button/activity-button.effects';
import { EquipmentTaskEffects } from './store/equipment-task/equipment-task.effects';
import { WorkOrderEffects } from './store/work-order/work-order.effects';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import { Subscription } from 'rxjs';
import * as AppActions from './store/app/actions';
import { MaskComponent } from './shared/component/mask/mask.component';
import { JwtInterceptor } from './jwt.interceptor';
import { getApiUrl, getFileUploadServiceUrl, getProxyUrl } from './utilities';
import { LineAvailabilityEffects } from './store/line-availability/line-availability.effects';
import { PageHeaderService } from './shared/service/page-header/page-header.service';
import { FilterLineEffects } from './store/filter/line/line.effects';
import { FilterActivityEffects } from './store/filter/activity/activity.effects';
import { FilterSiteEffects } from './store/filter/site/site.effects';
import { FilterProductEffects } from './store/filter/product/product.effects';
import { FilterShiftEffects } from './store/filter/shift/shift.effects';
import { WorkOrderScheduleEffects } from './store/work-order-schedule/work-order-schedule.effects';
import { ProductMultiplierEffects } from './store/settings/product-multiplier/product-multiplier.effects';
import { FilterSensorTypeEffects } from './store/filter/sensor-type/sensor-type.effects';
import { IndependentFilterProductEffects } from './store/filter/no-dependency-product/no-dependency-product.effects';
import { FilterDepartmentEffects } from './store/filter/department/department.effects';
import { FilterObservationEffects } from './store/filter/observation/observation.effects';
import { ShiftSummaryEffects } from './store/shift-summary/shift-summary.effects';
import { WorkOrdersSummaryEffects } from './store/work-orders-summary/work-orders-summary.effects';
import { TaskGroupsEffects } from './store/task-groups/task-groups.effects';
import { CheckInLogEffects } from './store/reports/check-in-log/check-in-log.effects';
import { CookieService } from 'ngx-cookie-service';
import { ForbiddenComponent } from './view/error/forbidden/forbidden.component';
import { AlertPauseEffects } from './store/alert-pause/alert-pause.effects';
import { SensorStatusesEffects } from './store/sensor-statuses/sensor-statuses.effects';
import { GlobalErrorHandlerService } from './shared/service/error-service/global-error-handler.service';
import { MonitoringService } from './shared/service/error-service/monitoring.service';
import { PhaseSelectionEffects } from './store/phase-selection/phase-selection.effects';
import { SchedulerScenarioDeployService } from './shared/service/scheduler-scenario-deploy/scheduler-scenario-deploy.service';
import { ForbiddenSchedulerComponent } from './view/error/forbidden-scheduler/forbidden-scheduler.component';
import { CustomTranslateLoader } from './custom-translate-loader';
import { RootCauseGroupEffects } from './store/settings/root-cause-group/root-cause-group.effects';
import { FilterRootCauseTypeEffects } from './store/filter/root-cause-type/root-cause-type.effects';
import { CheckInEffects } from './store/check-in/check-in.effects';
import { LineViewWorkOrderSummaryEffects } from './store/line-view-work-order-summary/line-view-work-order-summary.effects';
import { AssetViewEffects } from './store/asset-view/asset-view.effects';
import { LineStationEffects } from './store/line-station/line-station.effects';
import { UserConfigurationEffects } from './store/user-configuration/user-configuration.effects';
import { ProductsEffects } from './store/settings/products/products.effects';
import { FilterSourceObjectsEffects } from './store/filter/check-in-log-source-object/source-object.effects';
import { EquipmentListsEffects } from './store/settings/equipment-lists/equipment-lists.effects';
import { ProductSpeedsEffects } from './store/settings/product-speeds/product-speeds.effects';
import { EquipmentAssignmentEffects } from './store/settings/equipment-assignment/equipment-assignment.effects';
import { TasksEffects } from './store/settings/tasks/tasks.effects';
import { AdvancedFilterEffects } from './store/advanced-filter/advanced-filter.effects';
import { CustomersEffects } from './store/settings/customers/customers.effects';
import { UserSettingsEffects } from './store/settings/users/user.effects';
import { AccountEffects } from './store/settings/account/account.effect';
import { SitesEffects } from './store/settings/sites/sites.effects';
import { ActivitiesEffects } from './store/settings/activities/activities.effects';
import { SensorEffects } from './store/sensor-management/sensor-management.effects';
import { ActivityTimelineEffects } from './store/reports/activity-timeline/activity-timeline.effects';
import { FilterActivityTypeEffects } from './store/filter/activity-type/activity-type.effects';
import { KpiTargetsEffects } from './store/settings/kpi-targets/kpi-targets.effects';
import { ActivityLogsEffects } from './store/reports/activity-logs/activity-logs.effects';
import { MomentDatePipe } from './standalone/pipes/moment-date.pipe';
import { FilterStartOfDayEffects } from './store/filter/startOfDay/startOfDay.effects';
import { FilterSensorEffects } from './store/filter/sensor/sensor.effects';
import { SensorReportsEffects } from './store/reports/sensor-reports/sensor-reports.effects';
import { RootCauseAnalysisEffects } from './store/reports/root-cause-analysis/root-cause-analysis.effects';
import { RootCauseAnalysisElasticEffects } from './store/reports/root-cause-analysis-elastic/root-cause-analysis-elastic.effects';
import { FilterRootCauseGroupEffects } from './store/filter/root-cause-group/root-cause-group.effects';
import { ActivityViewEffects } from './store/dashboards/activity-view/activity-view.effects';
import { FilterUserEffects } from './store/filter/user/user.effects';
import { FilterProductFamilyEffects } from './store/filter/product-family/product-family.effects';
import { SkillMatrixEffects } from './store/reports/skill-matrix/skill-matrix.effects';
import { WorkOrderPerformanceEffects } from './store/reports/work-order-performance/work-order-performance.effects';
import { FilterWorkOrderEffects } from './store/filter/work-order/work-order.effects';
import { ActivityLogsTableEffects } from './store/activity-logs-table/activity-logs-table.effects';
import { DepartmentsEffects } from './store/settings/departments-lines-stations/departments/departments.effects';
import { LinesEffects } from './store/settings/departments-lines-stations/lines/lines.effects';
import { StationsEffects } from './store/settings/departments-lines-stations/stations/stations.effects';
import { ProductSpeedAnalysisEffects } from './store/reports/product-speed-analysis/product-speed-analysis.effects';
import { EquipmentsEffects } from './store/settings/equipments/equipments.effects';
import { EquipmentTypesEffects } from './store/settings/equipment-types/equipment-types.effects';
import { EquipmentBrandsEffects } from './store/settings/equipment-brands/equipment-brands.effects';
import { LaborPerformanceEffects } from './store/reports/labor-performance/labor-performance.effects';
import { DeepDiveAnalysisEffects } from './store/reports/deep-dive-analysis/deep-dive-analysis.effects';
import { CapacityEffects } from './store/reports/capacity/capacity.effect';
import { TaskPerformanceEffects } from './store/reports/task-performance/task-performance.effects';
import { FilterEquipmentEffects } from './store/filter/equipment/equipment.effects';
import { FilterTaskEffects } from './store/filter/task/task.effects';
import { KpiViewEffects } from './store/dashboards/kpi-view/kpi-view.effects';
import { DailyPerformanceSummaryEffects } from './store/reports/daily-performance-summary/daily-performance-summary.effects';
import { LookupsEffect } from './store/lookups/lookups.effect';
import { ManualCountEffects } from './store/work-order-manual-count/effects';
import { DigitalFormEffects } from './store/digital-form/digital-form.effects';
import { TagsEffects } from './store/settings/tags/tags.effects';
import { TreeNodesEffects } from './store/settings/tree-nodes/tree-nodes.effects';
import { NotificationGroupsEffects } from './store/settings/nofication-center/notification-groups/notification-groups.effects';
import { NotificationTemplatesEffects } from './store/settings/notification-center/notification-templates/notification-templates.effects';
import { NotificationScopeEffects } from './store/settings/notification-scope/notification-scope.effects';
import { NotificationSetupEffects } from './store/settings/notification-setup/notification-setup.effects';
import { AlertsEffects } from './store/settings/alerts/alerts.effects';
import { OeeCalendarEffects } from './store/reports/oee-calendar/oee-calendar.effects';
import { MaintenanceComponent } from './layout/maintenance/maintenance.component';
import { CheckInLogElasticEffects } from './store/reports/check-in-log-elastic/check-in-log-elastic.effects';
import { PeriodicOeeCalculationReviewEffects } from './store/reports/periodic-oee-calculation-review/periodic-oee-calculation-review.effects';
import { StationActivityHistoryEffects } from './store/station-activity-history/station-activity-history.effects';
import { DailyProductionPerformanceEffects } from './store/dashboards/daily-production-performance/daily-production-performance.effects';
import { PerformanceDeepDiveEffects } from './store/reports/performance-deep-dive/performance-deep-dive.effects';
import { GlobalViewEffects } from './store/global-view/global-view.effects';
import { LaborSchedulerEffects } from './store/labor-scheduler/labor-scheduler.effects';
import { MyReportsEffects } from './store/reports/my-reports/my-reports.effects';
import { MyReportMetricsEffects } from './store/reports/my-report-metrics/my-reports-metrics.effects';
import { CommentLogsEffects } from './store/reports/comment-logs/comment-logs.effects';
import { ProductResourceGroupsEffects } from './store/settings/product-resource-groups/product-resource-groups-effects';
import { BillOfMaterialsEffects } from './store/settings/products/product-structure/bill-of-materials/bill-of-materials.effects';
import { ProductVersionsEffects } from './store/settings/products/product-structure/versions/product-versions.effects';
import { ResourcesEffects } from './store/settings/products/product-structure/resources/resources.effects';
import { JobsEffects } from './store/settings/jobs/jobs.effects';
import { ManualCountReviewEffects } from './store/reports/work-order-count-review/work-order-count-review.effects';
import { MaintenancePerformanceEffects } from './store/reports/maintenance-performance/maintenance-performance.effects';
import { FloorPlansEffects } from './store/settings/departments-lines-stations/floor-plans/floor-plans.effects';
import { FilterFloorPlanEffects } from './store/filter/floor-plan/floor-plan.effects';
import { SiteViewEffects } from './store/global-view/site-view/site-view.effects';
import { MicrostopsEffects } from './store/reports/microstops/microstops.effects';
import { OeeWaterfallAnalysisEffects } from './store/reports/oee-waterfall-analysis/oee-waterfall-analysis.effects';
import { NotificationFeedModalComponent } from './standalone/modals/notification-feed-modal/notification-feed-modal.component';
import { ScheduleAdherenceEffects } from './store/reports/schedule-adherence/schedule-adherence.effects';
import { ProductFamilyEffects } from './store/settings/product-family/product-family.effects';
import { LineViewEnhancedEffects } from './store/dashboards/line-view-enhanced/line-view-enhanced.effects';
import { FilterCustomerSegmentEffects } from './store/filter/customer-segment/customer-segment.effects';
import { OPCStatusIndicatorComponent } from './layout/main/opc-status-indicator/opc-status-indicator.component';
import { HomePageDisplaySettingsEffects } from './store/settings/home-page-display-settings/home-page-display-settings.effects';
import { PushNotificationPermissionModalComponent } from './standalone/modals/push-notification-permission-modal/push-notification-permission-modal.component';
import { PushNotificationEffects } from './store/push-notification/push-notification.effects';
import { ProductTransitionMatrixEffects } from './store/settings/product-transition-matrix/product-transition-matrix.effects';
import { ChangeoverEffects } from './store/reports/changeover/changeover.effects';
import { DoNotDisturbScheduleEffects } from './store/do-not-disturb-schedule/do-not-disturb-schedule.effects';
import { TagFilterEffects } from './store/filter/tag/tag.effects';
import { LaborAssetViewEffects } from './store/dashboards/labor-asset-view/labor-asset-view.effects';
import { FilterProductOrProductFamilyEffects } from './store/filter/product-or-product-family/product-or-product-family.effects';
import { OpcMessageInspectionEffects } from './store/reports/opc-message-inspection/opc-message-inspection.effects';
import { FilterOpcTagEffects } from './store/filter/opc-tag/opc-tag.effects';
import { FilterCustomerEffects } from './store/filter/customer/customer.effects';
import { OtifAnalysisEffects } from './store/reports/otif-analysis/otif-analysis.effect';
import { LineViewEffects } from './store/global-view/site-view/line-view/line-view.effects';
import { FileUploadEffects } from './store/file-upload/file-upload.effects';
import { ProductionViewEffects } from './store/dashboards/production-view/production-view.effects';
import { NotificationLogsEffects } from './store/settings/notification-logs/notification-logs.effects';
import { FilterNotificationRecipientUserEffects } from './store/filter/notification-recipient-user/notification-recipient-user.effects';
import { OeeAdherenceEffects } from './store/reports/oee-adherence/oee-adherence.effects';
import { LaborAssetHomeEffects } from './store/home/labor-asset-home/labor-asset-home.effects';
import { FilterLineStationEffects } from './store/filter/line-station/line-station.effects';
import { FilterJobEffects } from './store/filter/job/job.effects';
import { JobPerformanceEffects } from './store/reports/job-performance/job-performance.effects';
import { MultiLineChangeActivityEffects } from './store/home/multi-line-change-activity/multi-line-change-activity.effects';
import { LayoutPlanViewEffects } from './store/layout-plan-view/layout-plan-view.effects';
import { UnitConversionEffects } from './store/settings/unit-conversion/unit-conversion-effects.service';
import { ActivityButtonMultiLineEffects } from './store/activity-button-multi-line/activity-button-multi-line.effects';
import { PageHeaderEffects } from './store/page-header/page-header.effects';
import { FilterIotDeviceReportDeviceTypeEffects } from './store/filter/iot-device-report-device-type/iot-device-report-device-type.effects';
import { SemiManualCountButtonCardEffects } from './store/station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.effects';
import { StationHomeKpiCardComponentEffects } from './store/station-home/kpi-card/kpi-card.effects';
import { CreateQuickIssueActionModalComponent } from './shared/component/create-quick-issue-action-modal/create-quick-issue-action-modal.component';
import { ActionTrackerEffects } from './store/action-tracker/action-tracker.effects';
import { IotDeviceReportEffects } from './store/iot-device-report/iot-device-report.effects';
import { LinePathProductConfigurationEffects } from './store/settings/product-configuration/line-path-product-configuration/line-path-product-configuration.effects';
import { SchedulerWorkOrderSetEffects } from './store/scheduler-work-order-set/scheduler-work-order-set.effects';
import { HomeMiniKpiSettingsEffects } from './store/settings/home-mini-kpi-settings/home-mini-kpi-settings.effects';
import { CheckInTimelineComponent } from './view/reports/check-in-timeline/check-in-timeline.component';
import { HomeModule } from './view/home/home.module';
import { LaborAssetHomeModule } from './view/home/labor-asset-home/labor-asset-home.module';
import { CheckInTimelineEffects } from './store/reports/check-in-timeline/check-in-timeline.effects';
import { FilterIotDeviceEffects } from './store/filter/iot-device-filter/iot-device-filter.effects';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PageLoaderComponent,
    TopLoaderComponent,
    MaskComponent,
    ForbiddenComponent,
    ForbiddenSchedulerComponent,
    MaintenanceComponent,
    CheckInTimelineComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MomentDatePipe,
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot({
      maxOpened: 8,
      preventDuplicates: true,
      countDuplicates: false,
    }),
    EffectsModule.forRoot([
      CommentTypesEffects,
      ProductionReviewEffects,
      SiteLineSelectionEffects,
      UserEffects,
      HomeEffects,
      ActivityHistoryEffects,
      AppEffects,
      MainEffects,
      ActivityReviewEffects,
      SchedulerEffects,
      SchedulerScenarioEffects,
      ActivityButtonEffects,
      ActivityButtonMultiLineEffects,
      EquipmentTaskEffects,
      WorkOrderEffects,
      LineAvailabilityEffects,
      FilterLineEffects,
      FilterActivityEffects,
      FilterActivityTypeEffects,
      FilterSensorTypeEffects,
      FilterShiftEffects,
      FilterSiteEffects,
      FilterProductEffects,
      FilterUserEffects,
      FilterProductFamilyEffects,
      FilterDepartmentEffects,
      FilterRootCauseTypeEffects,
      FilterRootCauseGroupEffects,
      FilterObservationEffects,
      FilterWorkOrderEffects,
      FilterCustomerSegmentEffects,
      WorkOrderScheduleEffects,
      IndependentFilterProductEffects,
      ProductMultiplierEffects,
      ShiftSummaryEffects,
      WorkOrdersSummaryEffects,
      TaskGroupsEffects,
      CheckInLogEffects,
      AlertPauseEffects,
      SensorStatusesEffects,
      PhaseSelectionEffects,
      RootCauseGroupEffects,
      CheckInEffects,
      LineViewWorkOrderSummaryEffects,
      AssetViewEffects,
      LineStationEffects,
      UserConfigurationEffects,
      ProductsEffects,
      ProductSpeedsEffects,
      FilterSourceObjectsEffects,
      EquipmentListsEffects,
      EquipmentAssignmentEffects,
      TasksEffects,
      AdvancedFilterEffects,
      CustomersEffects,
      UserSettingsEffects,
      PageHeaderEffects,
      AccountEffects,
      SitesEffects,
      ActivitiesEffects,
      SensorEffects,
      ProductResourceGroupsEffects,
      ActivityTimelineEffects,
      KpiTargetsEffects,
      ActivityLogsEffects,
      SensorReportsEffects,
      FilterStartOfDayEffects,
      FilterSensorEffects,
      RootCauseAnalysisEffects,
      RootCauseAnalysisElasticEffects,
      ActivityViewEffects,
      SkillMatrixEffects,
      WorkOrderPerformanceEffects,
      FilterWorkOrderEffects,
      ActivityLogsTableEffects,
      DepartmentsEffects,
      LinesEffects,
      StationsEffects,
      ProductSpeedAnalysisEffects,
      EquipmentsEffects,
      EquipmentTypesEffects,
      EquipmentBrandsEffects,
      LaborPerformanceEffects,
      DeepDiveAnalysisEffects,
      CapacityEffects,
      TaskPerformanceEffects,
      FilterEquipmentEffects,
      FilterTaskEffects,
      KpiViewEffects,
      DailyPerformanceSummaryEffects,
      LookupsEffect,
      ManualCountEffects,
      DigitalFormEffects,
      TagsEffects,
      NotificationGroupsEffects,
      NotificationTemplatesEffects,
      NotificationScopeEffects,
      NotificationSetupEffects,
      AlertsEffects,
      OeeCalendarEffects,
      CheckInLogElasticEffects,
      TreeNodesEffects,
      PeriodicOeeCalculationReviewEffects,
      StationActivityHistoryEffects,
      DailyProductionPerformanceEffects,
      PerformanceDeepDiveEffects,
      GlobalViewEffects,
      LaborSchedulerEffects,
      MyReportsEffects,
      MyReportMetricsEffects,
      CommentLogsEffects,
      BillOfMaterialsEffects,
      ProductVersionsEffects,
      ResourcesEffects,
      JobsEffects,
      ManualCountReviewEffects,
      MaintenancePerformanceEffects,
      FloorPlansEffects,
      FilterFloorPlanEffects,
      SiteViewEffects,
      MicrostopsEffects,
      OeeWaterfallAnalysisEffects,
      ScheduleAdherenceEffects,
      ProductFamilyEffects,
      LineViewEnhancedEffects,
      HomePageDisplaySettingsEffects,
      ProductTransitionMatrixEffects,
      ChangeoverEffects,
      DoNotDisturbScheduleEffects,
      TagFilterEffects,
      PushNotificationEffects,
      LaborAssetViewEffects,
      FilterProductOrProductFamilyEffects,
      OpcMessageInspectionEffects,
      FilterOpcTagEffects,
      FilterCustomerEffects,
      OtifAnalysisEffects,
      LineViewEffects,
      FileUploadEffects,
      OeeAdherenceEffects,
      ProductionViewEffects,
      NotificationLogsEffects,
      FilterNotificationRecipientUserEffects,
      LaborAssetHomeEffects,
      FilterLineStationEffects,
      FilterJobEffects,
      JobPerformanceEffects,
      MultiLineChangeActivityEffects,
      LayoutPlanViewEffects,
      UnitConversionEffects,
      FilterJobEffects,
      JobPerformanceEffects,
      FilterIotDeviceReportDeviceTypeEffects,
      SemiManualCountButtonCardEffects,
      StationHomeKpiCardComponentEffects,
      ActionTrackerEffects,
      IotDeviceReportEffects,
      LinePathProductConfigurationEffects,
      HomeMiniKpiSettingsEffects,
      CheckInTimelineEffects,
      SchedulerWorkOrderSetEffects,
      FilterIotDeviceEffects,
    ]),
    StoreModule.forRoot(oeeAppReducer, {
      // runtimeChecks: {
      //   strictStateImmutability: false,
      //   strictActionImmutability: false,
      // },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MainModule,
    ServiceWorkerModule.register('./scw-df-sw.js', { enabled: environment.production }),
    OPCStatusIndicatorComponent,
    NotificationFeedModalComponent,
    PushNotificationPermissionModalComponent,
    CreateQuickIssueActionModalComponent,
    HomeModule,
    LaborAssetHomeModule,
  ],
  schemas: [],
  providers: [
    MenuItems,
    HelperService,
    WINDOW_PROVIDERS,
    HomeActivityTreeChartService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: 'API_BASE_URL',
      useFactory: getApiUrl,
    },
    {
      provide: 'PROXY_URL',
      useFactory: getProxyUrl,
    },
    {
      provide: 'FILE_UPLOAD_URL',
      useFactory: getFileUploadServiceUrl,
    },
    CheckForUpdateService,
    PageHeaderService,
    CookieService,
    MonitoringService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    DatePipe,
    SchedulerScenarioDeployService,
    MomentDatePipe,
  ],
  bootstrap: [AppComponent],
  exports: [PageLoaderComponent],
})
export class AppModule {
  // Used to initialize service worker update registration
  constructor(private readonly checkForUpdateService: CheckForUpdateService) {}
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  store: Store<OeeAppState>,
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any, reject: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve());
      let userStore: Subscription;
      let translateSubscription: Subscription;

      translate.setDefaultLang('en');
      store.dispatch(new AppActions.GetMaintenanceModeData());
      store.dispatch(new AppActions.GetCurrentUser());

      locationInitialized.then(() => {
        userStore = store.select('user').subscribe((state) => {
          if (state.language !== null) {
            if (userStore) {
              userStore.unsubscribe();
            }

            const langToSet = state.language;
            translateSubscription = translate.use(langToSet).subscribe(
              (translateResponseData) => {
                if (translateResponseData !== undefined) {
                  resolve();
                }
              },
              () => {
                reject();
              },
              () => {
                if (translateSubscription) {
                  translateSubscription.unsubscribe();
                }
              },
            );
          }

          if (state.locale !== '') {
            HelperService.setDefaultMomentLocaleDayOfWeek(state.locale);
          }
        });
      });
    });
}
